export const thunder = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className="h-6 w-6"
    height="1.5em"
    width="1.5em"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
    ></path>
  </svg>
);

export const sun = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-6 w-6"
    height="1.5em"
    width="1.5em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="5"></circle>
    <line x1="12" y1="1" x2="12" y2="3"></line>
    <line x1="12" y1="21" x2="12" y2="23"></line>
    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
    <line x1="1" y1="12" x2="3" y2="12"></line>
    <line x1="21" y1="12" x2="23" y2="12"></line>
    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
  </svg>
);

export const alert = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-6 w-6"
    height="1.5em"
    width="1.5em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
    <line x1="12" y1="9" x2="12" y2="13"></line>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </svg>
);

export const send = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 30 30"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-4 w-4 mr-1"
    height="4em"
    width="4em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
  </svg>
);

export const boticon = (
  <svg
    viewBox="0 0 100 200"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-4 w-4 mr-1"
    height="4em"
    width="4em"
  >
    <path
      fill="#77B5FE"
      d="M50,0 L100,50 L60,70 L60,110 L100,120 L100,200 L0,200 L0,120 L40,110 L40,70 L0,50 L50,0z"
    />
    <path fill="#3F3D56" d="M60,70 L40,70 L40,90 L60,90z" />
    <path fill="#C4C4C4" d="M47,40 L53,40 L53,70 L47,70z" />
    <circle cx="50" cy="25" r="15" fill="#C4C4C4" />
  </svg>
);


export const bot = (
  <svg 
  fill="cyan" width="0.6em" height="0.6em" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
   <defs>
    <symbol id="e" overflow="visible">
     <path d="m8.7344-0.51562c-0.44922 0.23047-0.91797 0.40234-1.4062 0.51562-0.49219 0.125-1 0.1875-1.5312 0.1875-1.5859 0-2.8398-0.44141-3.7656-1.3281-0.91797-0.88281-1.375-2.0859-1.375-3.6094 0-1.5195 0.45703-2.7227 1.375-3.6094 0.92578-0.88281 2.1797-1.3281 3.7656-1.3281 0.53125 0 1.0391 0.0625 1.5312 0.1875 0.48828 0.11719 0.95703 0.29297 1.4062 0.53125v1.9688c-0.44922-0.3125-0.89844-0.53906-1.3438-0.6875-0.4375-0.14453-0.90234-0.21875-1.3906-0.21875-0.875 0-1.5625 0.28125-2.0625 0.84375s-0.75 1.3359-0.75 2.3125c0 0.98047 0.25 1.75 0.75 2.3125s1.1875 0.84375 2.0625 0.84375c0.48828 0 0.95312-0.070312 1.3906-0.21875 0.44531-0.14453 0.89453-0.36719 1.3438-0.67188z"/>
    </symbol>
    <symbol id="c" overflow="visible">
     <path d="m6.3906-5.1875c-0.19922-0.09375-0.39844-0.16016-0.59375-0.20312-0.19922-0.050781-0.39844-0.078125-0.59375-0.078125-0.58594 0-1.0391 0.1875-1.3594 0.5625-0.3125 0.375-0.46875 0.91797-0.46875 1.625v3.2812h-2.2812v-7.1406h2.2812v1.1719c0.28906-0.45703 0.625-0.79688 1-1.0156 0.38281-0.21875 0.84375-0.32812 1.375-0.32812 0.082031 0 0.16406 0.007812 0.25 0.015625 0.09375 0.011719 0.22266 0.027344 0.39062 0.046875z"/>
    </symbol>
    <symbol id="a" overflow="visible">
     <path d="m8.2188-3.5938v0.65625h-5.3281c0.050781 0.53125 0.24219 0.93359 0.57812 1.2031 0.33203 0.27344 0.79688 0.40625 1.3906 0.40625 0.47656 0 0.96875-0.070313 1.4688-0.21875 0.5-0.14453 1.0156-0.35938 1.5469-0.64062v1.75c-0.53125 0.21094-1.0703 0.36719-1.6094 0.46875-0.54297 0.10156-1.0859 0.15625-1.625 0.15625-1.293 0-2.2969-0.32812-3.0156-0.98438-0.71094-0.65625-1.0625-1.5781-1.0625-2.7656 0-1.1641 0.34766-2.082 1.0469-2.75 0.70703-0.66406 1.6758-1 2.9062-1 1.125 0 2.0195 0.33984 2.6875 1.0156 0.67578 0.67969 1.0156 1.5781 1.0156 2.7031zm-2.3438-0.75c0-0.4375-0.13281-0.78516-0.39062-1.0469-0.25-0.26953-0.57812-0.40625-0.98438-0.40625-0.4375 0-0.79688 0.125-1.0781 0.375-0.27344 0.25-0.44531 0.60938-0.51562 1.0781z"/>
    </symbol>
    <symbol id="j" overflow="visible">
     <path d="m4.2969-3.2031c-0.48047 0-0.83984 0.078125-1.0781 0.23438-0.24219 0.15625-0.35938 0.39844-0.35938 0.71875 0 0.28125 0.09375 0.50781 0.28125 0.67188 0.19531 0.16797 0.46875 0.25 0.8125 0.25 0.42578 0 0.78516-0.14844 1.0781-0.45312 0.30078-0.3125 0.45312-0.70312 0.45312-1.1719v-0.25zm3.4844-0.875v4.0781h-2.2969v-1.0625c-0.3125 0.4375-0.66406 0.75781-1.0469 0.95312-0.375 0.19531-0.83984 0.29688-1.3906 0.29688-0.74219 0-1.3398-0.21094-1.7969-0.64062-0.46094-0.4375-0.6875-1-0.6875-1.6875 0-0.83203 0.28516-1.4414 0.85938-1.8281 0.57031-0.39453 1.4766-0.59375 2.7188-0.59375h1.3438v-0.1875c0-0.35156-0.14844-0.61328-0.4375-0.78125-0.28125-0.17578-0.72656-0.26562-1.3281-0.26562-0.49219 0-0.94922 0.054687-1.375 0.15625-0.41797 0.09375-0.80469 0.24219-1.1562 0.4375v-1.7344c0.48828-0.125 0.97656-0.21875 1.4688-0.28125 0.48828-0.0625 0.98438-0.09375 1.4844-0.09375 1.2812 0 2.207 0.25781 2.7812 0.76562 0.57031 0.51172 0.85938 1.3359 0.85938 2.4688z"/>
    </symbol>
    <symbol id="b" overflow="visible">
     <path d="m3.5938-9.1562v2.0156h2.3438v1.6406h-2.3438v3.0156c0 0.33594 0.0625 0.5625 0.1875 0.6875 0.13281 0.11719 0.39453 0.17188 0.78125 0.17188h1.1719v1.625h-1.9531c-0.89844 0-1.5312-0.1875-1.9062-0.5625s-0.5625-1.0156-0.5625-1.9219v-3.0156h-1.1406v-1.6406h1.1406v-2.0156z"/>
    </symbol>
    <symbol id="i" overflow="visible">
     <path d="m5.9531-6.0938v-3.8125h2.2969v9.9062h-2.2969v-1.0312c-0.3125 0.41797-0.66406 0.72656-1.0469 0.92188-0.375 0.19531-0.8125 0.29688-1.3125 0.29688-0.875 0-1.5938-0.34766-2.1562-1.0469-0.5625-0.69531-0.84375-1.5977-0.84375-2.7031 0-1.1016 0.28125-2.0039 0.84375-2.7031 0.5625-0.69531 1.2812-1.0469 2.1562-1.0469 0.5 0 0.9375 0.10156 1.3125 0.29688 0.38281 0.19922 0.73438 0.50781 1.0469 0.92188zm-1.5 4.625c0.47656 0 0.84766-0.17578 1.1094-0.53125 0.25781-0.36328 0.39062-0.88281 0.39062-1.5625 0-0.67578-0.13281-1.1914-0.39062-1.5469-0.26172-0.36328-0.63281-0.54688-1.1094-0.54688-0.49219 0-0.86719 0.18359-1.125 0.54688-0.25 0.35547-0.375 0.87109-0.375 1.5469 0 0.67969 0.125 1.1992 0.375 1.5625 0.25781 0.35547 0.63281 0.53125 1.125 0.53125z"/>
    </symbol>
    <symbol id="h" overflow="visible">
     <path d="m4.8906-1.4688c0.48828 0 0.85938-0.17578 1.1094-0.53125 0.25781-0.36328 0.39062-0.88281 0.39062-1.5625 0-0.67578-0.13281-1.1914-0.39062-1.5469-0.25-0.36328-0.62109-0.54688-1.1094-0.54688-0.49219 0-0.86719 0.18359-1.125 0.54688-0.26172 0.35547-0.39062 0.87109-0.39062 1.5469 0 0.67969 0.12891 1.1992 0.39062 1.5625 0.25781 0.35547 0.63281 0.53125 1.125 0.53125zm-1.5156-4.625c0.3125-0.41406 0.66016-0.72266 1.0469-0.92188 0.38281-0.19531 0.82031-0.29688 1.3125-0.29688 0.88281 0 1.6094 0.35156 2.1719 1.0469 0.5625 0.69922 0.84375 1.6016 0.84375 2.7031 0 1.1055-0.28125 2.0078-0.84375 2.7031-0.5625 0.69922-1.2891 1.0469-2.1719 1.0469-0.49219 0-0.92969-0.10156-1.3125-0.29688-0.38672-0.19531-0.73438-0.50391-1.0469-0.92188v1.0312h-2.2812v-9.9062h2.2812z"/>
    </symbol>
    <symbol id="g" overflow="visible">
     <path d="m0.15625-7.1406h2.2812l1.9219 4.8438 1.625-4.8438h2.2812l-3 7.8125c-0.30469 0.78906-0.65625 1.3438-1.0625 1.6562-0.39844 0.32031-0.92188 0.48438-1.5781 0.48438h-1.3125v-1.5h0.70312c0.38281 0 0.66406-0.0625 0.84375-0.1875 0.17578-0.11719 0.31641-0.33594 0.42188-0.65625l0.0625-0.1875z"/>
    </symbol>
    <symbol id="f" overflow="visible">
     <path d="m5-5.8281c0.39453 0 0.69141-0.082031 0.89062-0.25 0.19531-0.17578 0.29688-0.42578 0.29688-0.75 0-0.33203-0.10156-0.58203-0.29688-0.75-0.19922-0.17578-0.49609-0.26562-0.89062-0.26562h-1.3438v2.0156zm0.09375 4.1562c0.48828 0 0.85938-0.10156 1.1094-0.3125 0.25-0.20703 0.375-0.51953 0.375-0.9375 0-0.41406-0.125-0.72266-0.375-0.92188-0.25-0.20703-0.62109-0.3125-1.1094-0.3125h-1.4375v2.4844zm2.2812-3.4219c0.51953 0.15625 0.92578 0.44531 1.2188 0.85938 0.28906 0.40625 0.4375 0.90625 0.4375 1.5 0 0.92969-0.3125 1.6172-0.9375 2.0625-0.61719 0.44922-1.5586 0.67188-2.8281 0.67188h-4.0625v-9.5156h3.6719c1.3203 0 2.2812 0.20312 2.875 0.60938 0.59375 0.39844 0.89062 1.0312 0.89062 1.9062 0 0.46875-0.10938 0.86719-0.32812 1.1875-0.21875 0.32422-0.53125 0.5625-0.9375 0.71875z"/>
    </symbol>
    <symbol id="u" overflow="visible">
     <path d="m5.5469-7.9062c-0.75 0-1.3359 0.27734-1.75 0.82812-0.40625 0.55469-0.60938 1.3281-0.60938 2.3281s0.20312 1.7773 0.60938 2.3281c0.41406 0.55469 1 0.82812 1.75 0.82812s1.3281-0.27344 1.7344-0.82812c0.41406-0.55078 0.625-1.3281 0.625-2.3281s-0.21094-1.7734-0.625-2.3281c-0.40625-0.55078-0.98438-0.82812-1.7344-0.82812zm0-1.7812c1.5195 0 2.7109 0.4375 3.5781 1.3125 0.875 0.875 1.3125 2.0859 1.3125 3.625 0 1.543-0.4375 2.75-1.3125 3.625-0.86719 0.875-2.0586 1.3125-3.5781 1.3125-1.5312 0-2.7305-0.4375-3.5938-1.3125-0.86719-0.875-1.2969-2.082-1.2969-3.625 0-1.5391 0.42969-2.75 1.2969-3.625 0.86328-0.875 2.0625-1.3125 3.5938-1.3125zm-2.0156-2.4062h1.5v1.5625h-1.5zm2.5156 0h1.5v1.5625h-1.5z"/>
    </symbol>
    <symbol id="t" overflow="visible">
     <path d="m1.2031-9.5156h2.4531v3.4688l3.5312-3.4688h2.8438l-4.5781 4.5156 5.0469 5h-3.0625l-3.7812-3.75v3.75h-2.4531z"/>
    </symbol>
    <symbol id="s" overflow="visible">
     <path d="m5.7969-9.9062v1.4844h-1.2656c-0.32422 0-0.55469 0.0625-0.6875 0.1875-0.125 0.11719-0.1875 0.32031-0.1875 0.60938v0.48438h1.9531v1.6406h-1.9531v5.5h-2.2812v-5.5h-1.125v-1.6406h1.125v-0.48438c0-0.78125 0.21875-1.3516 0.65625-1.7188 0.4375-0.375 1.1094-0.5625 2.0156-0.5625z"/>
    </symbol>
    <symbol id="d" overflow="visible">
     <path d="m4.4844-5.6719c-0.5 0-0.88672 0.18359-1.1562 0.54688-0.26172 0.35547-0.39062 0.875-0.39062 1.5625s0.12891 1.2148 0.39062 1.5781c0.26953 0.35547 0.65625 0.53125 1.1562 0.53125s0.87891-0.17578 1.1406-0.53125c0.26953-0.36328 0.40625-0.89062 0.40625-1.5781s-0.13672-1.207-0.40625-1.5625c-0.26172-0.36328-0.64062-0.54688-1.1406-0.54688zm0-1.6406c1.2266 0 2.1875 0.33594 2.875 1 0.69531 0.66797 1.0469 1.5859 1.0469 2.75 0 1.1797-0.35156 2.1016-1.0469 2.7656-0.6875 0.65625-1.6484 0.98438-2.875 0.98438-1.2305 0-2.1953-0.32812-2.8906-0.98438-0.6875-0.66406-1.0312-1.5859-1.0312-2.7656 0-1.1641 0.34375-2.082 1.0312-2.75 0.69531-0.66406 1.6602-1 2.8906-1z"/>
    </symbol>
    <symbol id="r" overflow="visible">
     <path d="m7.7031-5.9531c0.28906-0.4375 0.63281-0.76953 1.0312-1 0.39453-0.23828 0.83203-0.35938 1.3125-0.35938 0.82031 0 1.4453 0.25781 1.875 0.76562 0.42578 0.5 0.64062 1.2344 0.64062 2.2031v4.3438h-2.2969v-3.7188c0.007813-0.050781 0.015625-0.10938 0.015625-0.17188v-0.25c0-0.50781-0.074219-0.87891-0.21875-1.1094-0.14844-0.22656-0.38672-0.34375-0.71875-0.34375-0.4375 0-0.77734 0.18359-1.0156 0.54688-0.23047 0.35547-0.35156 0.87109-0.35938 1.5469v3.5h-2.2969v-3.7188c0-0.78906-0.070313-1.3008-0.20312-1.5312-0.13672-0.22656-0.375-0.34375-0.71875-0.34375-0.4375 0-0.77734 0.18359-1.0156 0.54688-0.24219 0.35547-0.35938 0.86719-0.35938 1.5312v3.5156h-2.2969v-7.1406h2.2969v1.0469c0.28125-0.40625 0.60156-0.70703 0.96875-0.90625 0.36328-0.20703 0.76562-0.3125 1.2031-0.3125 0.48828 0 0.92188 0.12109 1.2969 0.35938 0.38281 0.24219 0.67188 0.57422 0.85938 1z"/>
    </symbol>
    <symbol id="q" overflow="visible">
     <path d="m8.2656-4.3438v4.3438h-2.2969v-3.3125c0-0.625-0.015625-1.0508-0.046875-1.2812-0.023437-0.23828-0.0625-0.41016-0.125-0.51562-0.09375-0.15625-0.21875-0.27344-0.375-0.35938-0.14844-0.082031-0.32031-0.125-0.51562-0.125-0.48047 0-0.85547 0.1875-1.125 0.5625-0.27344 0.36719-0.40625 0.87109-0.40625 1.5156v3.5156h-2.2812v-9.9062h2.2812v3.8125c0.34375-0.41406 0.70703-0.72266 1.0938-0.92188 0.38281-0.19531 0.8125-0.29688 1.2812-0.29688 0.82031 0 1.4453 0.25781 1.875 0.76562 0.42578 0.5 0.64062 1.2344 0.64062 2.2031z"/>
    </symbol>
    <symbol id="p" overflow="visible">
     <path d="m1.2031-9.5156h2.7344l3.4531 6.5312v-6.5312h2.3281v9.5156h-2.7344l-3.4688-6.5312v6.5312h-2.3125z"/>
    </symbol>
    <symbol id="o" overflow="visible">
     <path d="m1.0156-2.7812v-4.3594h2.2969v0.71875c0 0.38672-0.007812 0.87109-0.015625 1.4531v1.1719c0 0.57422 0.015625 0.99219 0.046875 1.25 0.03125 0.25 0.082031 0.43359 0.15625 0.54688 0.09375 0.14844 0.21094 0.26172 0.35938 0.34375 0.15625 0.074219 0.33203 0.10938 0.53125 0.10938 0.46875 0 0.83594-0.17969 1.1094-0.54688 0.26953-0.36328 0.40625-0.86719 0.40625-1.5156v-3.5312h2.2812v7.1406h-2.2812v-1.0312c-0.34375 0.41797-0.71094 0.72656-1.0938 0.92188-0.375 0.19531-0.79688 0.29688-1.2656 0.29688-0.82422 0-1.4531-0.25-1.8906-0.75-0.42969-0.50781-0.64062-1.25-0.64062-2.2188z"/>
    </symbol>
    <symbol id="n" overflow="visible">
     <path d="m8.2656-4.3438v4.3438h-2.2969v-3.3281c0-0.61328-0.015625-1.0352-0.046875-1.2656-0.023437-0.23828-0.0625-0.41016-0.125-0.51562-0.09375-0.15625-0.21875-0.27344-0.375-0.35938-0.14844-0.082031-0.32031-0.125-0.51562-0.125-0.48047 0-0.85547 0.1875-1.125 0.5625-0.27344 0.36719-0.40625 0.87109-0.40625 1.5156v3.5156h-2.2812v-7.1406h2.2812v1.0469c0.34375-0.41406 0.70703-0.72266 1.0938-0.92188 0.38281-0.19531 0.8125-0.29688 1.2812-0.29688 0.82031 0 1.4453 0.25781 1.875 0.76562 0.42578 0.5 0.64062 1.2344 0.64062 2.2031z"/>
    </symbol>
    <symbol id="m" overflow="visible">
     <path d="m1.2031-9.5156h4.0625c1.207 0 2.1328 0.27344 2.7812 0.8125 0.65625 0.53125 0.98438 1.2969 0.98438 2.2969s-0.32812 1.7734-0.98438 2.3125c-0.64844 0.53125-1.5742 0.79688-2.7812 0.79688h-1.6094v3.2969h-2.4531zm2.4531 1.7812v2.6562h1.3438c0.47656 0 0.84766-0.11328 1.1094-0.34375 0.25781-0.22656 0.39062-0.55469 0.39062-0.98438 0-0.42578-0.13281-0.75391-0.39062-0.98438-0.26172-0.22656-0.63281-0.34375-1.1094-0.34375z"/>
    </symbol>
    <symbol id="l" overflow="visible">
     <path d="m1.0938-7.1406h2.2812v7.0156c0 0.95703-0.23047 1.6875-0.6875 2.1875-0.46094 0.5-1.125 0.75-2 0.75h-1.125v-1.5h0.40625c0.42578 0 0.71875-0.10156 0.875-0.29688 0.16406-0.1875 0.25-0.57031 0.25-1.1406zm0-2.7656h2.2812v1.8594h-2.2812z"/>
    </symbol>
    <symbol id="k" overflow="visible">
     <path d="m6.8594-6.9062v1.8594c-0.3125-0.21875-0.625-0.375-0.9375-0.46875-0.3125-0.10156-0.63672-0.15625-0.96875-0.15625-0.63672 0-1.1328 0.1875-1.4844 0.5625-0.35547 0.36719-0.53125 0.88281-0.53125 1.5469 0 0.66797 0.17578 1.1875 0.53125 1.5625 0.35156 0.36719 0.84766 0.54688 1.4844 0.54688 0.35156 0 0.69141-0.050781 1.0156-0.15625 0.32031-0.10156 0.61719-0.25781 0.89062-0.46875v1.875c-0.35547 0.125-0.71875 0.21875-1.0938 0.28125-0.36719 0.070313-0.73047 0.10938-1.0938 0.10938-1.293 0-2.3047-0.32812-3.0312-0.98438-0.71875-0.66406-1.0781-1.5859-1.0781-2.7656 0-1.1758 0.35938-2.0938 1.0781-2.75 0.72656-0.66406 1.7383-1 3.0312-1 0.375 0 0.73828 0.039062 1.0938 0.10938 0.36328 0.0625 0.72656 0.16406 1.0938 0.29688z"/>
    </symbol>
   </defs>
   <g>
    <path d="m206.69 257.99c4.2539 0 7.6992 3.4453 7.6992 7.6992s-3.4453 7.6992-7.6992 7.6992c-37.762 0-71.949-15.293-96.66-40.004-24.738-24.738-40.031-58.898-40.031-96.66 0-37.734 15.293-71.922 40.031-96.66s58.898-40.031 96.66-40.031c37.734 0 71.922 15.293 96.66 40.031 24.715 24.715 40.004 58.898 40.004 96.66 0 4.2539-3.4453 7.6992-7.6992 7.6992s-7.6992-3.4453-7.6992-7.6992c0-33.457-13.598-63.781-35.543-85.754-21.945-21.945-52.246-35.516-85.727-35.516s-63.805 13.57-85.754 35.516c-21.945 21.945-35.516 52.246-35.516 85.754 0 33.48 13.57 63.781 35.516 85.727 21.973 21.945 52.27 35.543 85.754 35.543z"/>
    <path d="m206.69 229.28c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-29.828 0-56.836-12.082-76.383-31.629s-31.629-46.555-31.629-76.383 12.082-56.836 31.629-76.383 46.555-31.629 76.383-31.629 56.836 12.082 76.383 31.629 31.629 46.555 31.629 76.383c0 4.2539-3.4453 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-25.574-10.359-48.723-27.113-65.477-16.754-16.754-39.902-27.113-65.477-27.113s-48.723 10.359-65.477 27.113c-16.754 16.754-27.113 39.902-27.113 65.477 0 25.574 10.359 48.723 27.113 65.477s39.902 27.113 65.477 27.113z"/>
    <path d="m206.69 200.63c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-21.922 0-41.754-8.8711-56.133-23.25-14.352-14.352-23.25-34.211-23.25-56.105 0-21.922 8.8984-41.754 23.25-56.133 14.352-14.352 34.211-23.25 56.133-23.25s41.754 8.8984 56.105 23.25c14.352 14.352 23.25 34.211 23.25 56.133 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-17.668-7.1484-33.637-18.738-45.199-11.562-11.562-27.559-18.738-45.199-18.738-17.668 0-33.637 7.1484-45.199 18.738-11.562 11.562-18.738 27.559-18.738 45.199s7.1484 33.637 18.738 45.199c11.562 11.562 27.559 18.738 45.199 18.738z"/>
    <path d="m206.69 171.97c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-14.016 0-26.672-5.6641-35.855-14.848-9.1875-9.1875-14.848-21.844-14.848-35.855 0-14.016 5.6875-26.672 14.848-35.855 9.1875-9.1875 21.867-14.848 35.855-14.848 14.016 0 26.672 5.6875 35.855 14.848 9.1875 9.1875 14.848 21.867 14.848 35.855 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-9.7344-3.9414-18.555-10.336-24.949-6.3672-6.3945-15.188-10.336-24.922-10.336s-18.555 3.9414-24.949 10.336c-6.3945 6.3945-10.336 15.188-10.336 24.949 0 9.7344 3.9414 18.555 10.336 24.922 6.3945 6.3945 15.188 10.336 24.949 10.336z"/>
    <path d="m206.69 143.29c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-6.082 0-11.613-2.4805-15.605-6.4727-0.15625-0.15625-0.3125-0.33984-0.46875-0.52344-3.707-3.9414-5.9766-9.2383-5.9766-15.059 0-6.082 2.4805-11.586 6.4453-15.555l0.027343-0.027344c3.9922-3.9922 9.5-6.4453 15.555-6.4453 6.0547 0 11.562 2.4805 15.555 6.4453l0.027344 0.027344 0.027344-0.027344c3.9922 3.9922 6.4727 9.5 6.4727 15.605 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-1.8281-0.73047-3.4961-1.9297-4.6719l0.027344-0.027344c-1.1992-1.1992-2.8438-1.9297-4.6992-1.9297-1.8281 0-3.4961 0.73047-4.6992 1.9297-1.1992 1.1992-1.9297 2.8438-1.9297 4.6992 0 1.6953 0.625 3.2344 1.6719 4.4102l0.26172 0.26172c1.1992 1.1992 2.8438 1.9297 4.6719 1.9297z"/>
    <path d="m471.26 136.69c0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 37.734-15.293 71.922-40.031 96.66-24.715 24.715-58.898 40.004-96.66 40.004v0.027343c-2.5039 0-5.1914-0.10547-8.0625-0.26172-2.793-0.15625-5.4805-0.39062-8.0391-0.70312-4.2266-0.49609-7.2812-4.3047-6.7852-8.5352 0.49609-4.2266 4.3047-7.2812 8.5352-6.7852 2.4531 0.28516 4.8789 0.52344 7.2031 0.65234 2.1641 0.12891 4.5664 0.20703 7.1484 0.20703v0.027344c33.457 0 63.781-13.57 85.727-35.543 21.945-21.945 35.516-52.246 35.516-85.727zm-241.65 14.352c0.49609 4.2266-2.5586 8.0391-6.7852 8.5352-4.2266 0.49609-8.0391-2.5586-8.5352-6.7852-0.3125-2.582-0.54688-5.2461-0.70312-8.0391-0.15625-2.8438-0.26172-5.5312-0.26172-8.0391 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 2.582 0.078125 4.957 0.18359 7.1484 0.12891 2.3477 0.36719 4.75 0.65234 7.2031z"/>
    <path d="m442.58 136.69c0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 29.828-12.082 56.836-31.629 76.383s-46.555 31.629-76.383 31.629c-4.0703 0-8.1406-0.23438-12.238-0.70312-4.0977-0.46875-8.1172-1.1484-12.082-2.0625-4.1484-0.94141-6.7344-5.0898-5.793-9.2383 0.94141-4.1484 5.0898-6.7344 9.2383-5.793 3.3672 0.78125 6.8125 1.3555 10.336 1.7734 3.3906 0.39062 6.9141 0.57422 10.57 0.57422 25.574 0 48.723-10.359 65.477-27.113 16.754-16.754 27.113-39.902 27.113-65.477zm-182.83 20.902c0.94141 4.1484-1.6445 8.2734-5.793 9.2383-4.1484 0.94141-8.2734-1.6445-9.2383-5.793-0.91406-3.9648-1.6172-7.9844-2.0625-12.082-0.46875-4.0977-0.70312-8.1953-0.70312-12.238 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 3.6523 0.20703 7.1484 0.57422 10.543 0.39062 3.5234 0.99219 6.9688 1.7734 10.336z"/>
    <path d="m413.95 136.88c-0.10547-4.2539 3.2617-7.7773 7.5156-7.8828 4.2539-0.10547 7.7773 3.2617 7.8828 7.5156l0.050782 1.8008c0 20.98-8.793 40.188-22.914 54.281-14.039 14.016-33.402 22.938-54.566 23.434l-1.8789 0.050781c-21.922 0-41.754-8.8711-56.105-23.25-14.352-14.352-23.25-34.211-23.25-56.105 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 17.641 7.1484 33.637 18.738 45.199 11.562 11.586 27.559 18.738 45.199 18.738 1.2539 0 1.2773 0.027344 1.5117 0.027344h0.027344c17.094-0.41797 32.727-7.6211 44.078-18.973 11.301-11.273 18.344-26.617 18.344-43.348 0-1.2266 0.027344-1.1211 0-1.4336z"/>
    <path d="m385.27 136.69c0-4.2539 3.4453-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 14.016-5.6641 26.672-14.848 35.855-9.1875 9.1875-21.844 14.848-35.855 14.848-14.016 0-26.672-5.6875-35.855-14.848-9.1875-9.1875-14.848-21.844-14.848-35.855 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 9.7344 3.9414 18.555 10.336 24.922 6.3945 6.3945 15.188 10.336 24.949 10.336 9.7344 0 18.555-3.9414 24.949-10.336s10.336-15.188 10.336-24.922z"/>
    <path d="m356.62 136.69c0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 6.082-2.4805 11.613-6.4727 15.578-3.9922 3.9922-9.5 6.4727-15.578 6.4727-6.082 0-11.613-2.4805-15.578-6.4727-0.18359-0.15625-0.33984-0.33984-0.46875-0.52344-3.707-3.9414-5.9766-9.2383-5.9766-15.059 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 1.6953 0.625 3.2344 1.6719 4.4102l0.26172 0.26172c1.1992 1.1992 2.8438 1.9297 4.6719 1.9297 1.8281 0 3.4727-0.73047 4.6719-1.9297 1.1992-1.1992 1.9297-2.8438 1.9297-4.6719z"/>
    <path d="m206.69 158.74c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 37.734 0 71.922 15.293 96.66 40.031 24.715 24.715 40.004 58.898 40.004 96.66h0.027343c0 2.5039-0.10547 5.1914-0.26172 8.0625-0.15625 2.793-0.39062 5.4805-0.70312 8.0391-0.49609 4.2266-4.3047 7.2812-8.5352 6.7852-4.2266-0.49609-7.2812-4.3047-6.7852-8.5352 0.28516-2.4531 0.52344-4.8789 0.65234-7.2031 0.12891-2.1641 0.20703-4.5664 0.20703-7.1484h0.027344c0-33.457-13.57-63.781-35.543-85.727-21.945-21.945-52.246-35.516-85.727-35.516zm14.352 241.65c4.2266-0.49609 8.0391 2.5586 8.5352 6.7852 0.49609 4.2266-2.5586 8.0391-6.7852 8.5352-2.582 0.3125-5.2461 0.54688-8.0391 0.70312-2.8438 0.15625-5.5312 0.26172-8.0391 0.26172-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 2.582 0 4.957-0.078125 7.1484-0.18359 2.3477-0.12891 4.75-0.36719 7.2031-0.65234z"/>
    <path d="m206.69 187.4c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 29.828 0 56.836 12.109 76.383 31.629 19.547 19.547 31.629 46.555 31.629 76.383 0 4.0703-0.23438 8.1406-0.70312 12.238s-1.1484 8.1172-2.0625 12.082c-0.94141 4.1484-5.0898 6.7344-9.2383 5.793-4.1484-0.94141-6.7344-5.0898-5.793-9.2383 0.78125-3.3672 1.3555-6.8125 1.7734-10.336 0.39062-3.3906 0.57422-6.9141 0.57422-10.57 0-25.574-10.359-48.723-27.113-65.477-16.754-16.754-39.902-27.113-65.477-27.113zm20.902 182.83c4.1484-0.94141 8.2734 1.6445 9.2383 5.793 0.94141 4.1484-1.6445 8.2734-5.793 9.2383-3.9648 0.91406-7.9844 1.6172-12.082 2.0625-4.0977 0.46875-8.1953 0.70312-12.238 0.70312-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 3.6523 0 7.1484-0.20703 10.543-0.57422 3.5234-0.39062 6.9688-0.99219 10.336-1.7734z"/>
    <path d="m206.88 216.05c-4.2539 0.10547-7.7773-3.2617-7.8828-7.5156-0.10547-4.2539 3.2617-7.7773 7.5156-7.8828l1.8008-0.050782c20.98 0 40.188 8.793 54.281 22.914 14.016 14.039 22.938 33.402 23.434 54.566l0.050781 1.8789c0 21.922-8.8711 41.754-23.25 56.105-14.352 14.352-34.211 23.25-56.105 23.25-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 17.641 0 33.637-7.1484 45.199-18.738 11.586-11.562 18.738-27.559 18.738-45.199 0-1.2539 0.027344-1.2773 0.027344-1.5117v-0.027344c-0.41797-17.094-7.6211-32.727-18.973-44.078-11.273-11.301-26.617-18.344-43.348-18.344-1.2266 0-1.1211-0.027344-1.4336 0z"/>
    <path d="m206.69 244.73c-4.2539 0-7.7227-3.4453-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 14.016 0 26.672 5.6641 35.855 14.848 9.1875 9.1875 14.848 21.844 14.848 35.855 0 14.016-5.6875 26.672-14.848 35.855-9.1875 9.1875-21.844 14.848-35.855 14.848-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 9.7344 0 18.555-3.9414 24.922-10.336 6.3945-6.3945 10.336-15.188 10.336-24.949 0-9.7344-3.9414-18.555-10.336-24.949s-15.188-10.336-24.922-10.336z"/>
    <path d="m206.69 273.38c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 6.0547 0 11.562 2.4805 15.555 6.4727l0.027344 0.027344 0.027343-0.027344c3.9922 3.9922 6.4727 9.5 6.4727 15.578 0 6.082-2.4805 11.613-6.4727 15.578-3.9922 3.9922-9.5 6.4727-15.578 6.4727-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 1.8281 0 3.4727-0.73047 4.6719-1.9297 1.1992-1.1992 1.9297-2.8438 1.9297-4.6719 0-1.8281-0.73047-3.4727-1.9297-4.6719l0.027344-0.027343c-1.1992-1.1758-2.8438-1.9062-4.6719-1.9062z"/>
    <path d="m372.01 136.69c0 4.2539-3.4453 7.6992-7.6992 7.6992s-7.6992-3.4453-7.6992-7.6992c0-37.762 15.293-71.949 40.004-96.66 24.738-24.738 58.898-40.031 96.66-40.031 37.734 0 71.922 15.293 96.66 40.031s40.031 58.898 40.031 96.66c0 37.734-15.293 71.922-40.031 96.66-24.715 24.715-58.898 40.004-96.66 40.004-4.2539 0-7.6992-3.4453-7.6992-7.6992s3.4453-7.6992 7.6992-7.6992c33.457 0 63.781-13.598 85.754-35.543 21.945-21.945 35.516-52.246 35.516-85.727s-13.57-63.805-35.516-85.754c-21.945-21.945-52.246-35.516-85.754-35.516-33.48 0-63.781 13.57-85.727 35.516-21.945 21.973-35.543 52.27-35.543 85.754z"/>
    <path d="m400.72 136.69c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-29.828 12.082-56.836 31.629-76.383s46.555-31.629 76.383-31.629 56.836 12.082 76.383 31.629 31.629 46.555 31.629 76.383-12.082 56.836-31.629 76.383-46.555 31.629-76.383 31.629c-4.2539 0-7.7227-3.4453-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 25.574 0 48.723-10.359 65.477-27.113 16.754-16.754 27.113-39.902 27.113-65.477s-10.359-48.723-27.113-65.477c-16.754-16.754-39.902-27.113-65.477-27.113-25.574 0-48.723 10.359-65.477 27.113s-27.113 39.902-27.113 65.477z"/>
    <path d="m429.37 136.69c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-21.922 8.8711-41.754 23.25-56.133 14.352-14.352 34.211-23.25 56.105-23.25 21.922 0 41.754 8.8984 56.133 23.25 14.352 14.352 23.25 34.211 23.25 56.133s-8.8984 41.754-23.25 56.105c-14.352 14.352-34.211 23.25-56.133 23.25-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 17.668 0 33.637-7.1484 45.199-18.738 11.562-11.562 18.738-27.559 18.738-45.199 0-17.668-7.1484-33.637-18.738-45.199-11.562-11.562-27.559-18.738-45.199-18.738s-33.637 7.1484-45.199 18.738c-11.562 11.562-18.738 27.559-18.738 45.199z"/>
    <path d="m458.03 136.69c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-14.016 5.6875-26.672 14.848-35.855 9.1875-9.1875 21.844-14.848 35.855-14.848 14.016 0 26.672 5.6875 35.855 14.848 9.1875 9.1875 14.848 21.867 14.848 35.855 0 14.016-5.6875 26.672-14.848 35.855-9.1875 9.1875-21.867 14.848-35.855 14.848-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 9.7344 0 18.555-3.9414 24.949-10.336 6.3945-6.3672 10.336-15.188 10.336-24.922s-3.9414-18.555-10.336-24.949c-6.3945-6.3945-15.188-10.336-24.949-10.336-9.7344 0-18.555 3.9414-24.922 10.336-6.3945 6.3945-10.336 15.188-10.336 24.949z"/>
    <path d="m486.68 136.69c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-6.082 2.4805-11.586 6.4727-15.555l0.027344-0.027344c3.9922-3.9922 9.5-6.4453 15.555-6.4453 6.082 0 11.586 2.4805 15.555 6.4453l0.027344 0.027344 0.027344-0.027344c3.9922 3.9922 6.4453 9.5 6.4453 15.605 0 6.082-2.4805 11.613-6.4453 15.578-3.9922 3.9922-9.5 6.4727-15.605 6.4727-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 1.8281 0 3.4961-0.73047 4.6719-1.9297 1.1992-1.1992 1.9297-2.8438 1.9297-4.6719s-0.73047-3.4961-1.9297-4.6719l0.027344-0.027344c-1.1992-1.1992-2.8438-1.9297-4.6992-1.9297-1.8281 0-3.4961 0.73047-4.6992 1.9297-1.1758 1.1992-1.9062 2.8438-1.9062 4.6992z"/>
    <path d="m493.31 143.29c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-2.582 0-4.957 0.078125-7.1484 0.18359-2.3477 0.12891-4.75 0.36719-7.2031 0.65234-4.2266 0.49609-8.0391-2.5586-8.5352-6.7852-0.49609-4.2266 2.5586-8.0391 6.7852-8.5352 2.582-0.3125 5.2461-0.54688 8.0391-0.70312 2.8438-0.15625 5.5312-0.26172 8.0391-0.26172zm-135.73 120.59c0.49609-4.2266 4.3047-7.2812 8.5352-6.7852 4.2266 0.49609 7.2812 4.3047 6.7852 8.5352-0.28516 2.4531-0.52344 4.8789-0.65234 7.2031-0.12891 2.1641-0.20703 4.5664-0.20703 7.1484 0 33.48 13.57 63.805 35.516 85.727 21.945 21.945 52.27 35.543 85.727 35.543v-0.027344c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227v-0.027344c-37.762 0-71.922-15.293-96.66-40.004-24.738-24.738-40.031-58.898-40.031-96.66 0-2.5039 0.10547-5.1914 0.26172-8.0625 0.15625-2.793 0.39062-5.4805 0.70312-8.0391z"/>
    <path d="m493.31 171.97c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-3.6523 0-7.1484 0.20703-10.543 0.57422-3.5234 0.39062-6.9688 0.99219-10.336 1.7734-4.1484 0.94141-8.2734-1.6445-9.2383-5.793-0.94141-4.1484 1.6445-8.2734 5.793-9.2383 3.9648-0.91406 7.9844-1.6172 12.082-2.0625 4.0977-0.46875 8.1953-0.70312 12.238-0.70312zm-105.25 83.691c0.94141-4.1484 5.0898-6.7344 9.2383-5.793 4.1484 0.94141 6.7344 5.0898 5.793 9.2383-0.78125 3.3672-1.3555 6.8125-1.7734 10.336-0.39062 3.3906-0.57422 6.9141-0.57422 10.57 0 25.574 10.359 48.723 27.113 65.477 16.754 16.754 39.902 27.113 65.477 27.113 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-29.828 0-56.836-12.082-76.383-31.629s-31.629-46.555-31.629-76.383c0-4.0703 0.23438-8.1406 0.70312-12.238 0.46875-4.0977 1.1484-8.1172 2.0625-12.082z"/>
    <path d="m493.49 200.65c4.2539 0.10547 7.5938 3.6289 7.5156 7.8828-0.10547 4.2539-3.6289 7.6211-7.8828 7.5156h-1.4336c-16.727 0-32.074 7.0469-43.348 18.344-11.352 11.379-18.555 27.008-18.973 44.078v0.027344c0 0.20703 0.027344 0.23438 0.027344 1.5117 0 17.641 7.1484 33.637 18.738 45.199 11.562 11.562 27.559 18.738 45.199 18.738 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-21.922 0-41.754-8.8984-56.105-23.25-14.352-14.352-23.25-34.211-23.25-56.105l0.050781-1.8789c0.52344-21.191 9.4453-40.527 23.434-54.566 14.094-14.117 33.301-22.914 54.281-22.914l1.8008 0.050781z"/>
    <path d="m493.31 229.28c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-9.7344 0-18.555 3.9414-24.922 10.336-6.3945 6.3945-10.336 15.188-10.336 24.949 0 9.7344 3.9414 18.555 10.336 24.949 6.3945 6.3945 15.188 10.336 24.922 10.336 4.2539 0 7.7227 3.4453 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-14.016 0-26.672-5.6641-35.855-14.848-9.1875-9.1875-14.848-21.844-14.848-35.855 0-14.016 5.6875-26.672 14.848-35.855 9.1875-9.1875 21.844-14.848 35.855-14.848z"/>
    <path d="m493.31 257.94c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-1.8281 0-3.4961 0.73047-4.6719 1.9062l0.027343 0.027344c-1.2266 1.2266-1.957 2.8711-1.957 4.6719s0.75781 3.4727 1.957 4.6719c1.1992 1.1992 2.8438 1.9297 4.6719 1.9297 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-6.082 0-11.613-2.4805-15.578-6.4727-3.9648-3.9648-6.418-9.4727-6.418-15.578s2.4531-11.613 6.418-15.578l0.027344 0.027344 0.027344-0.027344c3.9922-3.9922 9.5-6.4727 15.555-6.4727z"/>
    <path d="m493.31 302.01c-4.2539 0-7.6992-3.4453-7.6992-7.6992s3.4453-7.6992 7.6992-7.6992c37.762 0 71.949 15.293 96.66 40.004 24.738 24.738 40.031 58.898 40.031 96.66 0 37.734-15.293 71.922-40.031 96.66s-58.898 40.031-96.66 40.031c-37.734 0-71.922-15.293-96.66-40.031-24.715-24.715-40.004-58.898-40.004-96.66 0-4.2539 3.4453-7.6992 7.6992-7.6992s7.6992 3.4453 7.6992 7.6992c0 33.457 13.598 63.781 35.543 85.754 21.945 21.945 52.246 35.516 85.727 35.516s63.805-13.57 85.754-35.516c21.945-21.945 35.516-52.246 35.516-85.754 0-33.48-13.57-63.781-35.516-85.727-21.973-21.945-52.27-35.543-85.754-35.543z"/>
    <path d="m493.31 330.72c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 29.828 0 56.836 12.082 76.383 31.629s31.629 46.555 31.629 76.383-12.082 56.836-31.629 76.383-46.555 31.629-76.383 31.629-56.836-12.082-76.383-31.629-31.629-46.555-31.629-76.383c0-4.2539 3.4453-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 25.574 10.359 48.723 27.113 65.477 16.754 16.754 39.902 27.113 65.477 27.113s48.723-10.359 65.477-27.113c16.754-16.754 27.113-39.902 27.113-65.477 0-25.574-10.359-48.723-27.113-65.477s-39.902-27.113-65.477-27.113z"/>
    <path d="m493.31 359.37c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 21.922 0 41.754 8.8711 56.133 23.25 14.352 14.352 23.25 34.211 23.25 56.105 0 21.922-8.8711 41.754-23.25 56.133-14.352 14.352-34.211 23.25-56.133 23.25s-41.754-8.8984-56.105-23.25c-14.352-14.352-23.25-34.211-23.25-56.133 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 17.668 7.1484 33.637 18.738 45.199 11.562 11.562 27.559 18.738 45.199 18.738 17.668 0 33.637-7.1484 45.199-18.738 11.562-11.562 18.738-27.559 18.738-45.199s-7.1484-33.637-18.738-45.199c-11.562-11.562-27.559-18.738-45.199-18.738z"/>
    <path d="m493.31 388.03c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 14.016 0 26.672 5.6875 35.855 14.848 9.1875 9.1875 14.848 21.844 14.848 35.855 0 14.016-5.6875 26.672-14.848 35.855-9.1875 9.1875-21.867 14.848-35.855 14.848-14.016 0-26.672-5.6875-35.855-14.848-9.1875-9.1875-14.848-21.867-14.848-35.855 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 9.7344 3.9414 18.555 10.336 24.949s15.188 10.336 24.922 10.336 18.555-3.9414 24.949-10.336c6.3945-6.3945 10.336-15.188 10.336-24.949 0-9.7344-3.9414-18.555-10.336-24.922-6.3945-6.3945-15.188-10.336-24.949-10.336z"/>
    <path d="m493.31 416.68c-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-4.2539 3.4727-7.7227 7.7227-7.7227 6.082 0 11.586 2.4805 15.555 6.4727l0.027344 0.027344 0.027344-0.027344c3.9922 3.9922 6.4453 9.5 6.4453 15.578 0 6.082-2.4805 11.613-6.4453 15.605-3.9922 3.9922-9.5 6.4453-15.605 6.4453-6.082 0-11.613-2.4805-15.578-6.4453-0.15625-0.18359-0.3125-0.33984-0.46875-0.52344-3.707-3.9414-5.9766-9.2383-5.9766-15.082 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 1.6953 0.625 3.2617 1.6719 4.4102l0.26172 0.26172c1.1992 1.1992 2.8438 1.9297 4.6719 1.9297s3.4961-0.73047 4.6719-1.9297c1.1992-1.1992 1.9297-2.8438 1.9297-4.6719s-0.73047-3.4727-1.9297-4.6719l0.027343-0.027343c-1.1992-1.1758-2.8438-1.9062-4.6992-1.9062z"/>
    <path d="m486.68 423.31c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-2.582-0.078125-4.957-0.18359-7.1484-0.12891-2.3477-0.36719-4.75-0.65234-7.2031-0.49609-4.2266 2.5586-8.0391 6.7852-8.5352 4.2266-0.49609 8.0391 2.5586 8.5352 6.7852 0.3125 2.582 0.54688 5.2461 0.70312 8.0391 0.15625 2.8438 0.26172 5.5312 0.26172 8.0391zm-120.59-135.73c4.2266 0.49609 7.2812 4.3047 6.7852 8.5352-0.49609 4.2266-4.3047 7.2812-8.5352 6.7852-2.4531-0.28516-4.8789-0.52344-7.2031-0.65234-2.1641-0.12891-4.5664-0.20703-7.1484-0.20703-33.48 0-63.805 13.57-85.727 35.516-21.945 21.945-35.543 52.27-35.543 85.727h0.027344c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227h0.027344c0-37.762 15.293-71.922 40.004-96.66 24.738-24.738 58.898-40.031 96.66-40.031 2.5039 0 5.1914 0.10547 8.0625 0.26172 2.793 0.15625 5.4805 0.39062 8.0391 0.70312z"/>
    <path d="m458.03 423.31c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-3.6523-0.20703-7.1484-0.57422-10.543-0.39062-3.5234-0.99219-6.9688-1.7734-10.336-0.94141-4.1484 1.6445-8.2734 5.793-9.2383 4.1484-0.94141 8.2734 1.6445 9.2383 5.793 0.91406 3.9648 1.6172 7.9844 2.0625 12.082 0.46875 4.0977 0.70312 8.1953 0.70312 12.238zm-83.691-105.25c4.1484 0.94141 6.7344 5.0898 5.793 9.2383-0.94141 4.1484-5.0898 6.7344-9.2383 5.793-3.3672-0.78125-6.8125-1.3555-10.336-1.7734-3.3906-0.39062-6.9141-0.57422-10.57-0.57422-25.574 0-48.723 10.359-65.477 27.113-16.754 16.754-27.113 39.902-27.113 65.477 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-29.828 12.109-56.836 31.629-76.383 19.547-19.547 46.555-31.629 76.383-31.629 4.0703 0 8.1406 0.23438 12.238 0.70312 4.0977 0.46875 8.1172 1.1484 12.082 2.0625z"/>
    <path d="m429.35 423.49c-0.10547 4.2539-3.6289 7.5938-7.8828 7.5156-4.2539-0.10547-7.6211-3.6289-7.5156-7.8828v-1.4336c0-16.727-7.0469-32.074-18.344-43.348-11.379-11.352-27.008-18.555-44.078-18.973h-0.027344c-0.20703 0-0.23438 0.027344-1.5117 0.027344-17.641 0-33.637 7.1484-45.199 18.738-11.562 11.562-18.738 27.559-18.738 45.199 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-21.922 8.8984-41.754 23.25-56.105 14.352-14.352 34.211-23.25 56.105-23.25l1.8789 0.050781c21.191 0.52344 40.527 9.4453 54.566 23.434 14.117 14.094 22.914 33.301 22.914 54.281l-0.050781 1.8008z"/>
    <path d="m400.72 423.31c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-9.7344-3.9414-18.555-10.336-24.922-6.3945-6.3945-15.188-10.336-24.949-10.336-9.7344 0-18.555 3.9414-24.949 10.336-6.3945 6.3945-10.336 15.188-10.336 24.922 0 4.2539-3.4453 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-14.016 5.6641-26.672 14.848-35.855 9.1875-9.1875 21.844-14.848 35.855-14.848 14.016 0 26.672 5.6875 35.855 14.848 9.1875 9.1875 14.848 21.844 14.848 35.855z"/>
    <path d="m372.04 423.31c0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-1.8281-0.73047-3.4727-1.9297-4.6719-1.2266-1.2266-2.8711-1.957-4.6719-1.957-1.8008 0-3.4727 0.75781-4.6719 1.957l-0.26172 0.26172c-1.043 1.1758-1.6719 2.7148-1.6719 4.4102 0 4.2539-3.4727 7.7227-7.7227 7.7227-4.2539 0-7.7227-3.4727-7.7227-7.7227 0-5.8203 2.2695-11.117 5.9766-15.059 0.15625-0.18359 0.3125-0.36719 0.46875-0.52344 3.9648-3.9648 9.4727-6.418 15.578-6.418 6.1055 0 11.613 2.4531 15.578 6.418 3.9922 3.9922 6.4727 9.5 6.4727 15.578z"/>
    <path d="m206.69 286.64c4.2539 0 7.6992 3.4453 7.6992 7.6992s-3.4453 7.6992-7.6992 7.6992c-33.457 0-63.781 13.598-85.754 35.543-21.945 21.945-35.516 52.246-35.516 85.727 0 33.48 13.57 63.805 35.516 85.754 21.945 21.945 52.246 35.516 85.754 35.516 33.48 0 63.781-13.57 85.727-35.516 21.945-21.973 35.543-52.27 35.543-85.754 0-4.2539 3.4453-7.6992 7.6992-7.6992s7.6992 3.4453 7.6992 7.6992c0 37.762-15.293 71.949-40.004 96.66-24.738 24.738-58.898 40.031-96.66 40.031-37.734 0-71.922-15.293-96.66-40.031-24.738-24.738-40.031-58.898-40.031-96.66 0-37.734 15.293-71.922 40.031-96.66 24.715-24.715 58.898-40.004 96.66-40.004z"/>
    <path d="m206.69 315.27c4.2539 0 7.7227 3.4453 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-25.574 0-48.723 10.359-65.477 27.113s-27.113 39.902-27.113 65.477c0 25.574 10.359 48.723 27.113 65.477s39.902 27.113 65.477 27.113c25.574 0 48.723-10.359 65.477-27.113 16.754-16.754 27.113-39.902 27.113-65.477 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 29.828-12.082 56.836-31.629 76.383s-46.555 31.629-76.383 31.629-56.836-12.082-76.383-31.629-31.629-46.555-31.629-76.383c0-29.828 12.082-56.836 31.629-76.383s46.555-31.629 76.383-31.629z"/>
    <path d="m206.69 343.92c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-17.668 0-33.637 7.1484-45.199 18.738-11.562 11.562-18.738 27.559-18.738 45.199 0 17.668 7.1484 33.637 18.738 45.199 11.562 11.562 27.559 18.738 45.199 18.738s33.637-7.1484 45.199-18.738c11.562-11.562 18.738-27.559 18.738-45.199 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 21.922-8.8711 41.754-23.25 56.133-14.352 14.352-34.211 23.25-56.105 23.25-21.922 0-41.754-8.8711-56.133-23.25-14.352-14.352-23.25-34.211-23.25-56.133 0-21.922 8.8984-41.754 23.25-56.105 14.352-14.352 34.211-23.25 56.133-23.25z"/>
    <path d="m206.69 372.58c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-9.7344 0-18.555 3.9414-24.949 10.336-6.3945 6.3945-10.336 15.188-10.336 24.922s3.9414 18.555 10.336 24.949c6.3945 6.3945 15.188 10.336 24.949 10.336 9.7344 0 18.555-3.9414 24.922-10.336 6.3945-6.3945 10.336-15.188 10.336-24.949 0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 14.016-5.6641 26.672-14.848 35.855-9.1875 9.1875-21.844 14.848-35.855 14.848-14.016 0-26.672-5.6875-35.855-14.848-9.1875-9.1875-14.848-21.867-14.848-35.855 0-14.016 5.6875-26.672 14.848-35.855 9.1875-9.1875 21.867-14.848 35.855-14.848z"/>
    <path d="m206.69 401.26c4.2539 0 7.7227 3.4727 7.7227 7.7227 0 4.2539-3.4727 7.7227-7.7227 7.7227-1.8281 0-3.4961 0.73047-4.6992 1.9062-1.1992 1.1992-1.9297 2.8438-1.9297 4.6992 0 1.8281 0.73047 3.4961 1.9297 4.6992l0.027343-0.027343c1.1992 1.1992 2.8438 1.9297 4.6719 1.9297s3.4727-0.73047 4.6719-1.9297 1.9297-2.8438 1.9297-4.6719c0-4.2539 3.4727-7.7227 7.7227-7.7227 4.2539 0 7.7227 3.4727 7.7227 7.7227 0 6.082-2.4805 11.613-6.4727 15.605-3.9922 3.9922-9.5 6.4453-15.578 6.4453-6.082 0-11.613-2.4805-15.605-6.4453l0.027344-0.027343-0.027344-0.027344c-3.9922-3.9922-6.4453-9.5-6.4453-15.555 0-6.0547 2.4805-11.562 6.4453-15.555l0.027344-0.027344c3.9922-3.9922 9.5-6.4727 15.555-6.4727z"/>
    <use x="70" y="599.144531" xlinkHref="#e"/>
    <use x="79.574219" y="599.144531" xlinkHref="#c"/>
    <use x="86.011719" y="599.144531" xlinkHref="#a"/>
    <use x="94.859375" y="599.144531" xlinkHref="#j"/>
    <use x="103.664063" y="599.144531" xlinkHref="#b"/>
    <use x="109.902344" y="599.144531" xlinkHref="#a"/>
    <use x="118.753906" y="599.144531" xlinkHref="#i"/>
    <use x="132.636719" y="599.144531" xlinkHref="#h"/>
    <use x="141.976562" y="599.144531" xlinkHref="#g"/>
    <use x="155.023438" y="599.144531" xlinkHref="#f"/>
    <use x="164.96875" y="599.144531" xlinkHref="#u"/>
    <use x="176.0625" y="599.144531" xlinkHref="#e"/>
    <use x="185.636719" y="599.144531" xlinkHref="#t"/>
    <use x="70" y="612.191406" xlinkHref="#s"/>
    <use x="75.675781" y="612.191406" xlinkHref="#c"/>
    <use x="82.113281" y="612.191406" xlinkHref="#d"/>
    <use x="91.074219" y="612.191406" xlinkHref="#r"/>
    <use x="109.214844" y="612.191406" xlinkHref="#b"/>
    <use x="115.453125" y="612.191406" xlinkHref="#q"/>
    <use x="124.742188" y="612.191406" xlinkHref="#a"/>
    <use x="138.132812" y="612.191406" xlinkHref="#p"/>
    <use x="149.054688" y="612.191406" xlinkHref="#d"/>
    <use x="158.019531" y="612.191406" xlinkHref="#o"/>
    <use x="167.308594" y="612.191406" xlinkHref="#n"/>
    <use x="181.136719" y="612.191406" xlinkHref="#m"/>
    <use x="190.703125" y="612.191406" xlinkHref="#c"/>
    <use x="197.136719" y="612.191406" xlinkHref="#d"/>
    <use x="206.101563" y="612.191406" xlinkHref="#l"/>
    <use x="210.574219" y="612.191406" xlinkHref="#a"/>
    <use x="219.421875" y="612.191406" xlinkHref="#k"/>
    <use x="227.15625" y="612.191406" xlinkHref="#b"/>
   </g>
  </svg>
);

export const chat = (
  <svg fill="yellow" width="0.7em" height="0.7em" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
   <defs>
    <symbol id="f" overflow="visible">
     <path d="m39.078-2.3281c-2.0117 1.043-4.1094 1.8281-6.2969 2.3594s-4.4648 0.79688-6.8281 0.79688c-7.0859 0-12.699-1.9766-16.844-5.9375-4.1367-3.957-6.2031-9.3281-6.2031-16.109 0-6.7891 2.0664-12.164 6.2031-16.125 4.1445-3.9688 9.7578-5.9531 16.844-5.9531 2.3633 0 4.6406 0.26562 6.8281 0.79688s4.2852 1.3242 6.2969 2.375v8.7969c-2.0312-1.3828-4.0391-2.3984-6.0156-3.0469-1.9688-0.64453-4.0469-0.96875-6.2344-0.96875-3.9062 0-6.9844 1.2578-9.2344 3.7656-2.2422 2.5-3.3594 5.9531-3.3594 10.359 0 4.3867 1.1172 7.8359 3.3594 10.344 2.25 2.5 5.3281 3.75 9.2344 3.75 2.1875 0 4.2656-0.32031 6.2344-0.96875 1.9766-0.64453 3.9844-1.6602 6.0156-3.0469z"/>
    </symbol>
    <symbol id="e" overflow="visible">
     <path d="m16.031-40.953v9.0625h10.516v7.2812h-10.516v13.531c0 1.4805 0.28906 2.4805 0.875 3 0.59375 0.52344 1.7656 0.78125 3.5156 0.78125h5.2344v7.2969h-8.7344c-4.0312 0-6.8867-0.83594-8.5625-2.5156-1.6797-1.6875-2.5156-4.5391-2.5156-8.5625v-13.531h-5.0781v-7.2812h5.0781v-9.0625z"/>
    </symbol>
    <symbol id="d" overflow="visible">
     <path d="m26.594-27.234v-17.078h10.266v44.312h-10.266v-4.6094c-1.3984 1.875-2.9453 3.25-4.6406 4.125-1.6875 0.875-3.6406 1.3125-5.8594 1.3125-3.9297 0-7.1562-1.5625-9.6875-4.6875-2.5234-3.125-3.7812-7.1445-3.7812-12.062 0-4.9141 1.2578-8.9375 3.7812-12.062 2.5312-3.125 5.7578-4.6875 9.6875-4.6875 2.1953 0 4.1445 0.44531 5.8438 1.3281 1.707 0.88672 3.2578 2.2578 4.6562 4.1094zm-6.7188 20.656c2.1875 0 3.8516-0.79688 5-2.3906 1.1445-1.5938 1.7188-3.9102 1.7188-6.9531 0-3.0391-0.57422-5.3594-1.7188-6.9531-1.1484-1.5938-2.8125-2.3906-5-2.3906-2.168 0-3.8242 0.79688-4.9688 2.3906-1.1484 1.5938-1.7188 3.9141-1.7188 6.9531 0 3.043 0.57031 5.3594 1.7188 6.9531 1.1445 1.5938 2.8008 2.3906 4.9688 2.3906z"/>
    </symbol>
    <symbol id="c" overflow="visible">
     <path d="m21.875-6.5781c2.1758 0 3.8359-0.79688 4.9844-2.3906 1.1562-1.5938 1.7344-3.9102 1.7344-6.9531 0-3.0391-0.57812-5.3594-1.7344-6.9531-1.1484-1.5938-2.8086-2.3906-4.9844-2.3906-2.1875 0-3.8672 0.80469-5.0312 2.4062-1.168 1.6055-1.75 3.918-1.75 6.9375 0 3.0234 0.58203 5.3359 1.75 6.9375 1.1641 1.6055 2.8438 2.4062 5.0312 2.4062zm-6.7812-20.656c1.4062-1.8516 2.9609-3.2227 4.6719-4.1094 1.707-0.88281 3.6719-1.3281 5.8906-1.3281 3.9375 0 7.1641 1.5625 9.6875 4.6875 2.5195 3.125 3.7812 7.1484 3.7812 12.062 0 4.918-1.2617 8.9375-3.7812 12.062-2.5234 3.125-5.75 4.6875-9.6875 4.6875-2.2188 0-4.1836-0.44531-5.8906-1.3281-1.7109-0.88281-3.2656-2.2539-4.6719-4.1094v4.6094h-10.188v-44.312h10.188z"/>
    </symbol>
    <symbol id="b" overflow="visible">
     <path d="m5.3594-42.516h13.953l9.6875 22.75 9.7344-22.75h13.922v42.516h-10.359v-31.094l-9.7969 22.922h-6.9531l-9.7969-22.922v31.094h-10.391z"/>
    </symbol>
    <symbol id="a" overflow="visible">
     <path d="m36.969-19.422v19.422h-10.25v-14.812c0-2.7891-0.0625-4.707-0.1875-5.75-0.125-1.0391-0.33984-1.8125-0.64062-2.3125-0.39844-0.65625-0.9375-1.1719-1.625-1.5469s-1.4648-0.5625-2.3281-0.5625c-2.1367 0-3.8125 0.82422-5.0312 2.4688-1.2109 1.6484-1.8125 3.9219-1.8125 6.8281v15.688h-10.188v-44.312h10.188v17.078c1.5391-1.8516 3.1719-3.2227 4.8906-4.1094 1.7266-0.88281 3.6406-1.3281 5.7344-1.3281 3.6875 0 6.4844 1.1328 8.3906 3.3906 1.9062 2.2617 2.8594 5.5469 2.8594 9.8594z"/>
    </symbol>
   </defs>
   <g>
    <path d="m368.71 36.594h-31.719c-64.555 0-126.46 25.645-172.11 71.293-45.648 45.648-71.289 107.56-71.289 172.11s25.641 126.46 71.289 172.11c45.648 45.648 107.56 71.293 172.11 71.293h257.76c3.0938 0 6.0625-1.2305 8.25-3.418s3.418-5.1562 3.418-8.25v-237.43c-0.074219-63.023-25.141-123.44-69.703-168.01-44.562-44.562-104.98-69.633-168.01-69.707zm214.38 463.48h-246.09c-58.367 0-114.34-23.184-155.61-64.457-41.273-41.27-64.457-97.246-64.457-155.61s23.184-114.34 64.457-155.61c41.27-41.273 97.246-64.457 155.61-64.457h31.719c56.836 0.0625 111.33 22.672 151.52 62.863 40.191 40.188 62.797 94.68 62.859 151.52z"/>
    <path d="m457.06 220.9h-194.06c-6.4414 0-11.664 5.2227-11.664 11.668 0 6.4414 5.2227 11.668 11.664 11.668h194.06c6.4414 0 11.668-5.2266 11.668-11.668 0-6.4453-5.2266-11.668-11.668-11.668z"/>
    <path d="m457.06 313.06h-107.06c-6.4414 0-11.668 5.2227-11.668 11.664 0 6.4453 5.2266 11.668 11.668 11.668h107.06c6.4414 0 11.668-5.2227 11.668-11.668 0-6.4414-5.2266-11.664-11.668-11.664z"/>
    <use x="70" y="735" xlinkHref="#f"/>
    <use x="220.503906" y="735" xlinkHref="#e"/>
    <use x="287.953125" y="735" xlinkHref="#d"/>
    <use x="350.015625" y="735" xlinkHref="#c"/>
    <use x="450.105469" y="735" xlinkHref="#b"/>
    <use x="549.683594" y="735" xlinkHref="#a"/>
   </g>
  </svg>
  
  );
